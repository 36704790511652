import React from "react"
import { DefaultLayout } from "layouts"
import { CaseStudiesInterior } from "containers"
import { CaseStudy } from "components"
import { graphql } from "gatsby"
import { get } from "lodash"
import { getImage } from "gatsby-plugin-image"

import bgHeader from "../images/bg-header.png"
import { getRelatedCaseStudies } from "helpers"

const Index = (props: any) => {
  const [relatedCaseStudiesProps, setRelatedCaseStudiesProps] =
    React.useState<IRelatedCaseStudies>({
      headline: "Related Cases",
      caseStudies: [],
    })
  const {
    data: {
      markdownRemark: {
        frontmatter: caseStudy,
        fields: { slug },
        id,
      },
    },
  } = props

  React.useEffect(() => {
    getRelatedCaseStudies(id).then(({ data }: any) => {
      setRelatedCaseStudiesProps(prevState => ({
        ...prevState,
        caseStudies: data.map(({ slug, ...rest }: ICaseStudy) => ({
          ...rest,
          slug: `/case-studies/${slug}`,
        })),
      }))
    })
  }, [])

  const caseStudyProps: ICaseStudy = {
    title: get(caseStudy, "title", ""),
    thumbnail: getImage(get(caseStudy, "thumbnail")),
    category: get(caseStudy, "category", ""),
    description: get(caseStudy, "description", ""),
    client: get(caseStudy, "clientName", ""),
    date: get(caseStudy, "date", ""),
    gallery: get(caseStudy, "gallery", []).map((img: any) => getImage(img)),
    slug: `/case-studies/${slug}`,
    layout: parseInt(get(caseStudy, "layout", 1)),
  }

  const boxWrapperProps = {
    heroProps: {
      background: bgHeader,
      headline: "Case Studies",
    },
    boxContent: <CaseStudy {...caseStudyProps} />,
    breadcrumbProps: {
      paths: [
        {
          path: "/",
          label: "Home",
        },
        {
          path: "/case-studies",
          label: "Case Studies",
        },
        {
          path: caseStudyProps.slug,
          label: caseStudyProps.title,
        },
      ],
    },
  }

  const caseStudiesProps: ICaseStudiesInteriorContainerProps = {
    boxWrapperProps,
    relatedCaseStudiesProps,
  }

  return (
    <DefaultLayout {...props}>
      <CaseStudiesInterior {...caseStudiesProps} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: {regex: "/(case-studies)/.*\\.md$/"}
    ) {
      fields {
        slug
      }
      id
      frontmatter {
        title
        date(formatString: "MMMM DD YYYY")
        category
        description
        clientName
        layout
        thumbnail {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        gallery {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`

export default Index
